import React from 'react';
import BiosbusinessImg1 from '../../../../assets/biosbusiness-thumb1.png';
import BiosbusinessImg2 from '../../../../assets/biosbusiness-thumb2.png';
import BiosbusinessImg3 from '../../../../assets/biosbusiness-thumb3.png';
import BiosbusinessImg4 from '../../../../assets/biosbusiness-thumb4.png';
import BiosbusinessImg5 from '../../../../assets/biosbusiness-thumb5.png';
import BiosbusinessImg6 from '../../../../assets/BiosbusinessImg6.png';
import BiosbusinessImg7 from '../../../../assets/BiosbusinessImg7.png';
import BiosbusinessImg8 from '../../../../assets/BiosbusinessImg8.png';
import BiosbusinessImg9 from '../../../../assets/BiosbusinessImg9.png';
import BiosbusinessImg10 from '../../../../assets/BiosbusinessImg10.png';
import BiosbusinessImg11 from '../../../../assets/BiosbusinessImg11.png';
import BiosbusinessImg12 from '../../../../assets/BiosbusinessImg12.png';
import { Grid, Card, CardContent } from '@material-ui/core';
import '../../../../scss/common.scss';

export default function BusinessSolutionsTitles() {
  return (
    <>
      {/*1st Tile*/}
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg1} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg2} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>&nbsp;</Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg1} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      {/*2nd Tile*/}
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>&nbsp;</Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg4} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg5} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg3} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      {/*3rd Tile*/}
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg6} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>&nbsp;</Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg7} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg11} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      {/*4th Tile*/}
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>&nbsp;</Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg9} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg10} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg8} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      {/*5th Tile*/}
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg9} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg12} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>&nbsp;</Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg8} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      {/*6th Tile*/}
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg4} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg5} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg3} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>&nbsp;</Grid>
      {/*7th Tile*/}
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg10} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <Card className="team-titles">
          <img src={BiosbusinessImg8} alt="Thumb Image" />
          <CardContent className="descp-box">
            <h4 className="profilename">Carla Mendleway jackson</h4>
            <p className="profile-details">Senior Executive, IT</p>
            <p className="profile-details">EMEA</p>
            <p className="profile-details">APAC</p>
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}