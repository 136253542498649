import React from 'react';
import './business-solutions-team.scss';
import { Grid } from '@material-ui/core';
import BusinessSolutionsTitles from './businesssolutionstitles';
import capsule from '../../../assets/capsules.png';
import Container from '@material-ui/core/Container';

export default function BusinessSolutionsTeam() {
  return (
    <>
    <div className='bios-business-presenter-cls'>
      <Container maxWidth="xl" className='zero-padding-div'>
        <div className='business-container'>
          <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={6} lg={6} md={8}>
                <h2 className='teambio-title'>our strategic business solutions team</h2>
                <p className='teambio-info'>A bunch of good and dedicated people that you could trust on. We are best known to bring in best of services which essentially makes your employee experience smoother. Mauris vitae fermentum odio. Curabitur porttitor pretium dui at blandit</p>
              </Grid>
              <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                <BusinessSolutionsTitles />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
      </div>
    </>
  )
}