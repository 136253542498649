import * as React from 'react';
import { Grid } from '@material-ui/core';
import TeamBioTitles from './team-bio-titles';
import './our-leaders.scss'
import "../../../scss/common.scss";
import Container from '@material-ui/core/Container';

export default function OurLeaders() {
    return (
        <>            
        <Container maxWidth="xl" className='zero-padding-div'>
            <div className="our-leaders">
                <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid item xl={6} lg={6} md={8}>
                            <h2 className="teambio-title">our leaders</h2>
                            <p className="teambio-info">A bunch of good and dedicated people that you could trust on. We are best known to bring in best of services which essentially makes your employee experience smoother.<br /> <br /> Let’s connect.</p>
                        </Grid>
                        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                            <TeamBioTitles />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Container>
        </>
    )
}