import React from 'react';
import './image-slider.scss';
import ImageSlider from '../../../staticcomponents/image-slider/index';
import { Container } from '@material-ui/core';

import Image1 from '../../../assets/achievement-1.png';
import Image2 from '../../../assets/achievement-2.png';
import Image3 from '../../../assets/achievement-3.png';
import Image4 from '../../../assets/achievement-4.png';
import Image5 from '../../../assets/achievement-5.png';
import Image6 from '../../../assets/achievement-6.png';


export default function TeamBioImageSlider() {
 
  const ImgArray= [Image1,Image2,Image3,Image4,Image5, Image6, Image1,Image2,Image3,Image4,Image5,Image6 ]
    
    return (
        <Container maxWidth="xl" className='zero-padding-div'>
           <ImageSlider ImageArray={ImgArray} class="teamBioSliders"/>
        </Container>
    )
}



