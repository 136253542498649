import React from 'react'
import Grid from '@material-ui/core/Grid'
import global_service_header_img from '../../../assets/global-services-header.png'
import './our-team-header.scss'
import Container from '@material-ui/core/Container';

export default function TeamBioHeader() {
    return (
        <>
            <div className='team-bio-header'>
                <Container maxWidth="xl" className='zero-padding-div'>
                    <Grid container className='header-container'>
                        <Grid item xs={6} sm={6} md={5} lg={5} xl={5}>
                            <Grid className="header-left-text">
                                <Grid>
                                    <h1 className='title'>our team</h1>
                                    <p>compassionate support. exceptional technology. unbound service.    ‘that’s cartus experience’</p>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
                            <div className='header-image'>
                                <img src={global_service_header_img} alt="where mobility meets agility" width="100%" />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}