import React, { useState } from 'react';
import { Grid, Card, CardContent, Button, Slide, Dialog, DialogContent, IconButton, Box } from '@material-ui/core';
import AboutJewelsThumb1 from 'assets/aboutjewels-thumb1.png';
import AboutJewelsThumb2 from 'assets/aboutjewels-thumb2.png';
import AboutJewelsThumb3 from 'assets/aboutjewels-thumb3.png';
import AboutJewelsThumb4 from 'assets/aboutjewels-thumb4.png';
import AboutJewelsThumb5 from 'assets/aboutjewels-thumb5.png';
import AboutJewelsThumb6 from 'assets/aboutjewels-thumb6.png';
import AboutJewelsThumb7 from 'assets/aboutjewels-thumb7.png';
import Teambiopopup_profile from '../../../../assets/teambio-profile.png';
import Linkedin_logo from '../../../../assets/linkedlogo.png';
import '../../../../scss/common.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />;
  });
  

export default function TeamBioTitles() {

    const [open, setOpen] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    })
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

    return (
        <>
            
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                <Card className="team-titles" onClick={handleClickOpen}>
                    <img src={AboutJewelsThumb1} alt="Thumb Image" />
                    <CardContent className="descp-box">
                        <h4 className="profilename">Carla Mendleway</h4>
                        <p className="profile-details">Senior Executive, IT</p>
                        <p className="profile-details">EMEA</p>
                        <p className="profile-details">APAC</p>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                <Card className="team-titles">
                    <img src={AboutJewelsThumb2} alt="Thumb Image" />
                    <CardContent className="descp-box">
                        <h4 className="profilename">Glenda Sheffield-Adelie Haffeueser</h4>
                        <p className="profile-details">Executive Senior Vice President</p>
                        <p className="profile-details">Global Talent</p>
                        <p className="profile-details">EMEA & APAC</p>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>&nbsp;</Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                <Card className="team-titles">
                    <img src={AboutJewelsThumb3} alt="Thumb Image" />
                    <CardContent className="descp-box">
                        <h4 className="profilename">Sophia Johnson</h4>
                        <p className="profile-details">Senior Executive, IT</p>
                        <p className="profile-details">EMEA</p>
                        <p className="profile-details">APAC</p>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                <Card className="team-titles">
                    <img src={AboutJewelsThumb4} alt="Thumb Image" />
                    <CardContent className="descp-box">
                        <h4 className="profilename">Rob Gronkowski</h4>
                        <p className="profile-details">Senior Executive, IT</p>
                        <p className="profile-details">EMEA</p>
                        <p className="profile-details">APAC</p>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>&nbsp;</Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                <Card className="team-titles">
                    <img src={AboutJewelsThumb5} alt="Thumb Image" />
                    <CardContent className="descp-box">
                        <h4 className="profilename">Lavenelle Simone</h4>
                        <p className="profile-details">Senior Executive, IT</p>
                        <p className="profile-details">EMEA</p>
                        <p className="profile-details">APAC</p>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                <Card className="team-titles">
                    <img src={AboutJewelsThumb6} alt="Thumb Image" />
                    <CardContent className="descp-box">
                        <h4 className="profilename">Ben Harper</h4>
                        <p className="profile-details">Senior Executive, IT</p>
                        <p className="profile-details">EMEA</p>
                        <p className="profile-details">APAC</p>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>&nbsp;</Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                <Card className="team-titles">
                    <img src={AboutJewelsThumb7} alt="Thumb Image" />
                    <CardContent className="descp-box">
                        <h4 className="profilename">Glen Symonds</h4>
                        <p className="profile-details">Senior Executive, IT</p>
                        <p className="profile-details">EMEA</p>
                        <p className="profile-details">APAC</p>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>&nbsp;</Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                <Card className="team-titles">
                    <img src={AboutJewelsThumb4} alt="Thumb Image" />
                    <CardContent className="descp-box">
                        <h4 className="profilename">Rob Gronkowski</h4>
                        <p className="profile-details">Senior Executive, IT</p>
                        <p className="profile-details">EMEA</p>
                        <p className="profile-details">APAC</p>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                <Card className="team-titles">
                    <img src={AboutJewelsThumb1} alt="Thumb Image" />
                    <CardContent className="descp-box">
                        <h4 className="profilename">Carla Mendleway</h4>
                        <p className="profile-details">Senior Executive, IT</p>
                        <p className="profile-details">EMEA</p>
                        <p className="profile-details">APAC</p>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>&nbsp;</Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                <Card className="team-titles">
                    <img src={AboutJewelsThumb2} alt="Thumb Image" />
                    <CardContent className="descp-box">
                        <h4 className="profilename">Glenda Sheffield-Adelie Haffeueser</h4>
                        <p className="profile-details">Executive Senior Vice President</p>
                        <p className="profile-details">Global Talent</p>
                        <p className="profile-details">EMEA & APAC</p>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                <Card className="team-titles">
                    <img src={AboutJewelsThumb3} alt="Thumb Image" />
                    <CardContent className="descp-box">
                        <h4 className="profilename">Sophia Johnson</h4>
                        <p className="profile-details">Senior Executive, IT</p>
                        <p className="profile-details">EMEA</p>
                        <p className="profile-details">APAC</p>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>&nbsp;</Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                <Card className="team-titles">
                    <img src={AboutJewelsThumb7} alt="Thumb Image" />
                    <CardContent className="descp-box">
                        <h4 className="profilename">Lavenelle Simone</h4>
                        <p className="profile-details">Senior Executive, IT</p>
                        <p className="profile-details">EMEA</p>
                        <p className="profile-details">APAC</p>
                    </CardContent>
                </Card>
            </Grid>




 <Grid className="team_bio_popup">
      <Dialog open={open} TransitionComponent={Transition}  onClose={handleClose} maxWidth='md'>
        <Box className='team-bio-popup-overlay'>
          <div className='bg-donut'>
            <button className='popup-close-icon' onClick={handleClose}>
              
            </button>
            <DialogContent className='dialog-content'>
              <Grid container >
                <Grid item xl={4} lg={3} md={6} sm={5} xs={9} order={{ xs: 2, sm: 1, md: 1, lg: 1, xl: 1 }} className='desc-main-sec'>
                  <p className='main-desc'>Quisque iaculis diam metus, nec auctor mauris finibus id. Nam sit amet magna vitae enim molestie dapibus sit amet sed orci. Proin venenatis lobortis sollicitudin. Praesent lacinia orci vitae dictum pretium</p>
                </Grid>
                <Grid item xl={4} lg={4} md={8} sm={9} xs={12} order={{ xs: 3, sm: 3, md: 3, lg: 2, xl: 2 }}>
                  <p className='sub-desc'>Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta. Cras sit amet efficitur tortor, at placerat tortor. Curabitur in quam eu justo viverra lobortis. Suspendisse consectetur venenatis neque, quis vehicula augue pretium id. Sed finibus pretium tortor sagittis placerat. Sed vitae libero vestibulum, dictum turpis in, lobortis libero. In commodo vitae diam vel scelerisque. Curabitur quam nulla, bibendum eu felis ac, porttitor blandit risus. Quisque fermentum nisl vitae quam rhoncus, quis dapibus quam tincidunt. Phasellus dictum rhoncus neque, eu hendrerit arcu convallis sed.</p>
                </Grid>
                <Grid item xl={4} lg={5} md={6} sm={7} xs={12} order={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }} className="profile-main-sec">
                  <img src={Teambiopopup_profile} alt="Pofile image" className="profile-image" />
                  <div className='profile-desc-sec'>
                    <img src={Linkedin_logo} alt="Pofile image" className="linkedin-image" />
                    <p className='profile-name'>Glenda Sheffield-Adelie Haffeueser</p>
                    <p className='designation-sec'>Executive Senior Vice President ILS, EMEA & APAC</p>
                    <p className='email-sec'>alanmartinez@cartus.com</p>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
          </div>
        </Box>
      </Dialog>
</Grid>
        </>
    )
}