import * as React from 'react';
import { Grid } from '@material-ui/core';
import OurThougthTitles from './our-thougth-titles';
import './our-thougth-leaders.scss';
import "../../../scss/common.scss";
import Container from '@material-ui/core/Container';

export default function OurThougthLeaders() {
  return (
    <>
      <Container maxWidth="xl" className='zero-padding-div'>
        <div className='our-thougths-cls'>
          <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="flex-end" alignItems="flex-start">
                <Grid item container xl={6} lg={6} md={8}>
                  <h2 className='teambio-title'>our thought leaders</h2>
                  <p className='teambio-info'>A bunch of good and dedicated people that you could trust on. We are best known to bring in best of services which essentially makes your employee experience smoother. Mauris vitae fermentum odio. Curabitur porttitor pretium dui at blandit</p>
                </Grid>
              </Grid>
              <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                <OurThougthTitles />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  )
}