import React from 'react'
import Layout from '../../staticcomponents/layout'
import './team-bio.scss'
import TeamBioHeader from './our-team-header'
import OurLeaders from './our-leaders'
import BusinessSolutionsTeam from './business-solutions-team'
import OurThougthLeaders from './our-thougth-leaders'
import ImageSlider from './image-slider'
import LetsTalk from '../../staticcomponents/lets-talk'

export default function TeamBio() {
    return (
        <>
            <Layout>
                <div className='team-bio-main'>
                    {/* <div className='capsules-left'> */}
                        <TeamBioHeader />
                    {/* </div> */}
                    <div className='bg-donuts-left'>
                        <OurLeaders />
                    </div>
                    <div className='capsules-bg'>
                        <BusinessSolutionsTeam />
                    </div>
                    <div className='bg-donuts-right'>
                        <OurThougthLeaders />
                    </div>
                    <ImageSlider />
                    <LetsTalk />
                </div>
            </Layout>
        </>
    )
}